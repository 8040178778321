@use "../../config/" as *;
.waf-statsdetail {
  &.widget-layout-03 {
    padding: 0;
    overflow: unset;
    .table {
      &-row {
        border-bottom: 0.1rem solid rgba(var(--black-color-rgb), 0.05);
        border-left: 0.1rem solid rgba(var(--black-color-rgb), 0.05);
        background: var(--white-color);
        @include border-radius(0.3rem 0.3rem 0 0);
        @include flex-config(flex, null, null, center);
      }
      &-data {
        // flex: 1;
        flex-basis: 15%;
        padding: 0.5rem;
        display: none;
        @include flex-config(flex, null, center, center);
        // &:nth-child(-n + 3),
        // &.runs {
        //   display: flex;
        // }
        // &.number {
        //   flex-basis: 5%;
        // }
        &.team-name {
          flex-basis: 55%;
          justify-content: flex-start;
        }
      }
      .team {
        &-wrap {
          height: 100%;
          @include flex-config(flex, column, center, null);
          .player-name {
            position: relative;
            font-family: $font-bold;
            padding: 0.5rem 0;
            &:after {
              content: "";
              position: absolute;
              background-color: rgba(var(--black-color-rgb), 0.05);
              width: 100%;
              height: 0.1rem;
              bottom: -0.5rem;
              left: 0;
            }
          }
        }
        &-info {
          padding: 0.5rem 0;
          @include flex-config(flex, null, null, center);
        }
        &-logo {
          width: 3.5rem;
          height: 3.5rem;
          margin-right: 0.5rem;
          @include flex-config(flex, null, center, center);
        }
      }
      &-head {
        .table-row {
          background-color: var(--kabaddi-secondary-color);
        }
        .table-data {
          font-family: $font-regular;
          color: var(--white-color);
          text-align: center;
          // text-transform: uppercase;
          height: 3.8rem;
          span {
            font-size: 1.1rem;
          }
        }
      }
      &-body {
        .table-row {
          &:nth-child(even) {
            background-color: var(--light-grey-color);
          }
        }
        .table-data {
          height: 9rem;
          // border-right: 0.1rem solid rgba(var(--black-color-rgb), 0.05);
          span {
            font-family: $font-medium;
          }
          &.active {
            background-color: rgba(var(--kabaddi-primary-color-rgb), 0.4);
            color: var(--kabaddi-secondary-color);
            span {
              font-family: $font-bold;
              font-size: 1.5rem;
            }
          }
        }
        .loadmore-wrap {
          margin-top: 3rem;
        }
        .team-info {
          .team-name {
            .name {
              font-family: $font-medium;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
    .short-name {
      display: none;
    }
    .loadmore {
      padding: 0;
      color: var(--kabaddi-secondary-color);
      background-color: var(--kabaddi-primary-color);
    }
  }
}

@media screen and (min-width: $tablet-min-width) {
  .waf-statsdetail {
    &.widget-layout-03 {
      .layout-wrapper {
        max-width: var(--container-max-width);
        margin: 0 auto;
      }
      .table {
        &-data {
          flex-basis: 10%;
          &.team-name {
            flex-basis: 70%;
          }
          &.played-matches,
          &.total-points {
            padding: 0.5rem 2rem;
          }
        }
        &-head {
          .table-data {
            span {
              font-size: 1.4rem;
            }
          }
        }
        &-body {
          .table-data {
            &.active {
              span {
                font-size: 1.8rem;
              }
            }
          }
        }
      }
    }
  }
}
